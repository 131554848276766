import { Environment } from '@/infrastructure/adapters/environment-variables'
import { useAppStore } from '@/infrastructure/store/app'
import { computed, ComputedRef, defineComponent, onMounted, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'dl-version-header',
  setup() {
    const versionInfo = ref('')

    const headerMessage: ComputedRef<{ color: string; message: string } | null> = computed(() => {
      if (Environment) {
        return {
          color: getHeaderColor(),
          message: versionInfo.value
        }
      }
      return null
    })

    const getHeaderColor = (): string => {
      switch (Environment) {
        case 'test':
          return '#ff9800'
        case 'testat':
          return '#ff9800'
        case 'staging':
          return '#4CAF50'
        case 'beta':
          return '#2196F3'
        case 'development':
          return '#f44336'
      }
      return ''
    }

    onMounted(async () => {
      const appStore = useAppStore()
      versionInfo.value = await appStore.loadVersionInfo()
    })

    return { headerMessage }
  }
})
