import {
  resetAllTags,
  updateMetaDescription,
  updateMetaKeywords,
  updateMetaWithContent,
  updateTitle
} from '@/infrastructure/helpers/meta-tags'
import { NavigationGuard } from 'vue-router'
import { IRoute, IRouteMeta } from '../interfaces'
import { useAppStore } from '@/infrastructure/store/app'

export const MetaDataHandler: NavigationGuard = async (to: IRoute, from: IRoute, next) => {
  await updateVersionMetaData()
  HandleMetaData(to.meta)
  next()
}

export const HandleMetaData = (meta?: IRouteMeta | null) => {
  resetAllTags()
  updateTitle(meta?.title)
  updateMetaDescription(meta?.description)
  updateMetaKeywords(meta?.keywords)
  updateMetaWithContent('og:url', window.location.href, 'property')
  meta?.tags?.forEach((tag) => {
    updateMetaWithContent(tag.key, tag.content, tag.attribute)
  })
}

export async function updateVersionMetaData() {
  const appStore = useAppStore()
  const version = await appStore.loadVersionInfo()
  updateMetaWithContent('version', version, 'name')
}
